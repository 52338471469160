import React from "react";
import PortfolioPage from "../../index";
import { electronicLogos } from "../../data";

export default function ElectronicDigitalPage() {
  return (
    <div>
      <PortfolioPage heading="Electronic & Digital" data={electronicLogos} />
    </div>
  );
}
